import BackgroundImage from 'gatsby-background-image';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Dropdown, Navbar, Row } from 'react-bootstrap';
import Button from '../../components/button';
import { getLocalizedLink } from '../../helper/translation';
import Icon from '../icon';
import LanguageSwitch from '../languageSwitch';
import { SettingsContext } from '../layout';
import Link from '../link';
import SearchIcon from '../../../static/icons/icon_search.svg';
import SearchOverlay from '../../modules/search/searchOverlay';
import CrossLine from '../../../static/icons/icon_cross_line.svg';
import { cookieExists } from "../../helper/cookie";

const MobileMenuItem = ({ item }) => {
  const slug = item?.page?.localizedPage ? `/${item?.page?.fullSlug }`: item?.page?.url;
  return (
    <Link to={slug} className={`navbar-item ${item?.description ? 'description' : ''}`} >
      {item.name}
      {item?.description && (
        <p className="dropdown-item-text">{item.description}</p>
      )}
    </Link>
  );
};

export const MobileNavigation = () => {
  const settings = useContext(SettingsContext);
  const { node_locale, megaMenu } = settings;
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [history, setHistory] = useState([]);
  const [teaser, setTeaser] = useState(null);
  const [ searchVisible, setSearchVisible ] = useState(false);
  const [ pathname, setPathname ] = useState('');
  const [ isCookieRegistered, setCookieRegistered ] = useState(false);

  const handleSearchVisibility = () => {
    setSearchVisible(!searchVisible);
  };

  const checkRegistered = (item) =>
  {
    if (item?.visible === "Show always" || 
    (isCookieRegistered && item?.visible === "Show when registered cookie is set") || 
    (!isCookieRegistered && item?.visible === "Hide when registered cookie is set"))
    {
      return true;
    }
  }

  const checkItemsRegistered = (item) =>
  {
    if (item?.some((link) => link.visible === 'Show always') || 
    (isCookieRegistered && item?.some((link) => link.visible === 'Show when registered cookie is set') || 
    (!isCookieRegistered && item?.some((link) => link.visible === "Hide when registered cookie is set"))))
    {
      return true;
    }
  }

  useEffect(() => {
    const teaser = selected?.items?.filter(
      (type) => type?.internal?.type === 'ContentfulMegaMenuPromotion'
    );
    setTeaser(teaser);

    if (typeof window !== 'undefined') {
      setPathname(window.location.pathname.replaceAll('/', ''));
      setCookieRegistered(cookieExists('registered'));
    }
  }, [selected]);

  return (
    <>
    <header className="mobile">
      <Container fluid>
        <Navbar expand="md" bg="none">
          <div className="nav-bottom-row">
            <Navbar.Brand href={getLocalizedLink('/', node_locale)}>
              <img
                width="70"
                height="18"              
                src="/images/vier_logo.svg"
                alt="Vier logo"
                className="vier-logo"
              />
            </Navbar.Brand>
            {searchVisible ? <Button
              type={'button'}
              className={'closeBtn'}
              onClick={handleSearchVisibility}
              text={<CrossLine/>}
            /> : (<div className={'d-flex align-items-center'}>
            {/* <Button 
                className={'mobile-search-link'} 
                onClick={() => handleSearchVisibility()} 
                text={
                  <SearchIcon />
                }
              /> */}
              <Dropdown show={navigationOpen} drop={'left'}>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  onClick={() => setNavigationOpen(!navigationOpen)}
                />
                <Dropdown.Menu>
                  <Dropdown.Header>
                    <div className="dropdown-header-title">
                      {selected ? (
                        <div
                          className={'navbar-selected'}
                        >
                          <Icon name={'thinLeftArrow'}
                            onClick={() => {
                              if (history && history.length > 0) {
                                let tmp = [...history];
                                tmp.splice(history.length - 1, 1);
                                setSelected(history[history.length - 1]);
                                setHistory(tmp);
                              } else {
                                setSelected(null);
                              }
                            }} />
                          {selected.page ? <Link
                            to={
                              selected?.name === 'home' ? '/' : `/${selected?.page?.fullSlug}`
                            }
                          >
                            {selected?.name}
                          </Link> : <h3>{selected?.name}</h3>}
                          <div />
                        </div>
                      ) : (
                        <h3>Startseite</h3>
                      )}
                      <Icon
                        name="crossLine"
                        onClick={() => {
                          setNavigationOpen(false);
                          setSelected(null);
                        }}
                      />
                    </div>
                  </Dropdown.Header>

                  <Row className="container-row">
                    <div
                      className={selected?.links?.length > 4 ? 'mobile-overflow' : ''}
                    >
                      {selected
                        ? history.length <= 0
                          ? selected.items
                            ?.filter(
                              (type) =>
                                type?.internal?.type !==
                                'ContentfulMegaMenuPromotion'
                            )
                            .map((item) =>
                              ( selected.items !== null && (checkItemsRegistered(selected.items))) ? (
                                item.internal.type ===
                                  'ContentfulMegaMenuWrapper' ? (
                                  item.megaMenuElements?.map((wrapped) => (
                                    <div
                                      className="dropdown-item-wrapper"
                                      key={wrapped.contentful_id}
                                    >
                                      <div
                                        className="dropdown-item-inner-wrapper"
                                        onClick={() => {
                                          setHistory([...history, selected]);
                                          setSelected(wrapped);
                                        }}
                                      >
                                        <Dropdown.Item
                                          className={'navbar-item d-flex'}
                                        >
                                          {wrapped.name}
                                        
                                        <Icon name={'thinRightArrow'} />
                                        </Dropdown.Item>
                                      </div>
                                    </div>
                                  ))
                                ) : (checkRegistered(item)) && (
                                  <div
                                    className="dropdown-item-wrapper"
                                    key={item.contentful_id}
                                  >
                                    <div
                                      className="dropdown-item-inner-wrapper"
                                      onClick={(checkItemsRegistered(item.links)) && (() => {
                                        setHistory([...history, selected]);
                                        setSelected(item);
                                      })}
                                    >
                                      <Dropdown.Item
                                        href={(!checkItemsRegistered(item.links)) && '/' + item.page?.fullSlug}
                                        className={'navbar-item d-flex'}
                                      >
                                        {item.name}
                                        {(checkItemsRegistered(item.links)) && ( <Icon name={'thinRightArrow'} />)}
                                      </Dropdown.Item>
                                      
                                    </div>
                                  </div>
                                )
                              ) : (
                                <div
                                  className="dropdown-item-wrapper"
                                  key={item.contentful_id}
                                >
                                  <div className="dropdown-item-inner-wrapper">
                                    <Link
                                      to={`/${[...history, selected, item]
                                        .map((page) => page?.page?.fullSlug)
                                        .join('/')}`}
                                      className={`navbar-item ${pathname == item.page.fullSlug ? 'active' : ''}`}
                                    >
                                      {item.name}
                                    </Link>
                                  </div>
                                </div>
                              )
                            )
                          : (checkItemsRegistered(selected?.links)) &&
                            selected?.links?.map((item) => {
                              const { name, title, link, type, internal, contentful_id } = item
                              return internal?.type === 'ContentfulButton' ? (
                                <div
                                  className="dropdown-item-wrapper"
                                  key={item.contentful_id}
                                >
                                  <Button
                                    className="secondary"
                                    link={`/${link?.target?.fullSlug}`}
                                    type={type}
                                    text={title}
                                  />
                                </div>
                              ) : (checkRegistered(item)) && (
                                <div
                                  className="dropdown-item-wrapper"
                                  key={item.contentful_id}
                                >
                                  <div className="dropdown-item-inner-wrapper ">
                                    <MobileMenuItem item={item} />
                                  </div>
                                </div>
                              )
                            })
                        : megaMenu
                          ?.filter((item) => (checkRegistered(item)))
                          ?.filter((item) => item?.name)
                          .map((item) => (
                            <>
                              {item.items !== null ? (
                                <div
                                  className="dropdown-item-wrapper"
                                  key={item.contentful_id}
                                >
                                  <div className="dropdown-item-inner-wrapper">
                                    <Dropdown.Item
                                      onClick={(checkItemsRegistered(item.items)) && (() => {
                                        setSelected(item);
                                      })}
                                      className={`navbar-item ${pathname == item.page.fullSlug ? 'active' : ''}`}
                                      href={(!checkItemsRegistered(item.items)) && '/' + item.page?.fullSlug}
                                    >
                                      {item.name} 
                                      
                                      {(checkItemsRegistered(item.items)) && (<Icon name={'thinRightArrow'} />)}
                                    
                                    </Dropdown.Item>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="dropdown-item-wrapper"
                                  key={item.contentful_id}
                                >
                                  <div className="dropdown-item-inner-wrapper">
                                    <Link
                                      to={`/${[...history, selected, item]
                                        .map((page) => page?.page?.fullSlug)
                                        .join('/')}`}
                                      className={`d-flex navbar-item ${pathname == item.page.fullSlug ? 'active' : ''}`}
                                    >
                                      {item.name}
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                    </div>
                    <div className="mobile-teaser-wrapper">
                      {teaser &&
                        teaser.length > 0 &&
                        teaser
                          .filter((teaser) => teaser?.name)
                          .map((item) => (
                            <div
                              className={`mobile-teaser-container ${teaser && 'px-0'
                                }`}
                            key={item.contentful_id}>
                              <BackgroundImage
                                alt={item?.teaser?.title}
                                fixed={item?.teaser?.fixed}
                                style={{
                                  minWidth: '100%',
                                  width: 'unset',
                                  height: '100%',
                                }}
                              >
                                <div className="mobile-teaser-content">
                                  <h4 className="mobile-nav-teaser-title">
                                    {item?.name}
                                  </h4>
                                  <p className="mobile-nav-teaser-description">
                                    {item?.description}
                                  </p>
                                  <Button
                                    type={item?.button?.type}
                                    className={'mobile-nav-teaser-button'}
                                    text={item?.button?.title}
                                    link={item?.button?.link?.target?.fullSlug}
                                  />
                                </div>
                              </BackgroundImage>
                            </div>
                          ))}
                    </div>
                    <div>
                      <div className="mobile-nav-footer">
                        <Row className="mobile-nav-inner-wrapper">
                          <Col sm={12}>
                            <div className="mobile-nav-language-switch">
                              <LanguageSwitch />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Row>
                </Dropdown.Menu>
              </Dropdown>
            </div>)}
          </div>
        </Navbar>
      </Container>
    </header>
    {searchVisible && (
      <SearchOverlay onClose={() => setSearchVisible(false)} />
    )}
    </>
  );
};
