import React, { useContext, useState, useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import SearchIcon from '../../../static/icons/icon_search.svg';
import Button from '../../components/button';
import { getLocalizedLink } from '../../helper/translation';
import LanguageSwitch from '../languageSwitch';
import { SettingsContext } from '../layout';
import Link from '../link';
import { NavMenu } from './NavMenu/NavMenu';
import SearchOverlay from '../../modules/search/searchOverlay';
import { cookieExists } from "../../helper/cookie";

export const DesktopNavigation = () => {
  const { megaMenu, node_locale } = useContext(SettingsContext);
  const [ searchVisible, setSearchVisible ] = useState(false);
  const [ pathname, setPathname ] = useState('');
  const [ isCookieRegistered, setCookieRegistered ] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPathname(window.location.pathname.replaceAll('/', ''))
      setCookieRegistered(cookieExists('registered'));
    }
  }, []);

  const handleSearchVisibility = () => {
    setSearchVisible(!searchVisible);
  };
  return (
    <>
    <header className={`desktop ${searchVisible ? 'searchVisible' : ''}`}>
      <Container fluid>
        <Navbar expand="md" bg="none">
          <div className="nav-bottom-row">
            <Navbar.Brand href={getLocalizedLink('/', node_locale)}>
              <img
                width="70"
                height="18"
                src="/images/vier_logo.svg"
                alt="Vier logo"
                className="vier-logo"
              />
            </Navbar.Brand>
            <Nav>
              <ul className="navbar-nav">
                {megaMenu?.map(({ name, items, page, visible,contentful_id }) => (
                  <>
                    {(name && (visible === "Show always" || (isCookieRegistered && visible === "Show when registered cookie is set") || (!isCookieRegistered && visible === "Hide when registered cookie is set"))) && (
                      <li className={`nav-item ${pathname == page?.fullSlug ? 'active' : ''}`} key={contentful_id}>
                        <Link
                          className="nav-link"
                          to={
                            name === 'home' ? '/' : `/${page?.fullSlug}`
                          }
                        >
                          {name}
                        </Link>
                        {(items && (items.some((link) => link.visible === 'Show always')|| (isCookieRegistered && items.some((link) => link.visible === 'Show when registered cookie is set')) || (!isCookieRegistered && items.some((link) => link.visible === "Hide when registered cookie is set")))) && (
                          <NavMenu items={items} />
                        )}
                      </li>
                    )}
                  </>
                ))}
              </ul>
            </Nav>
            <div className="nav-search-container">
              <LanguageSwitch />
              {/* <Button 
                className={'search-link'} 
                onClick={() => handleSearchVisibility()} 
                text={
                  <SearchIcon />
                }
              /> */}
            </div>
          </div>
        </Navbar>
      </Container>
    </header>
    {searchVisible && (
      <SearchOverlay onClose={() => setSearchVisible(false)} />
    )}
    </>
  );
};
