import GatsbyImage from 'gatsby-image';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from './button';
import ColorContainer from './colorContainer';
import { getNestedProp } from '../helper/code';
import MarkDownComponent from './markDown'

const DiagonalBackgroundComponent = ({
  title,
  columns,
  images,
  colorContainer: color,
  links
}) => {
  const columnSizes = [
    {},
    {
      xs: 12,
      sm: 8,
      md: 8,
      lg: 8
    },
    {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4
    },
    {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 3
    }
  ];

  return (
    <ColorContainer
      className={'diagonal-background-color-container'}
      color={color}
    >
      <div className={'diagonal-background'}>
        <svg className={'position-absolute'} viewBox="0 15 1433 543">
          <defs>
            <path id="path-1" d="M0 0H1440V543H0z"></path>
            <path
              id="path-3"
              d="M0 16v3273.663l30.04-.8c27.696 0 46.13-13.962 58.809-38.322L1397.976 65.364c9.09-35.9-14.572-49.364-28.482-49.364H0z"
            ></path>
            <linearGradient
              id="linearGradient-5"
              x1="101.616%"
              x2="75.042%"
              y1="34.312%"
              y2="62.888%"
            >
              <stop offset="0%" stopColor="#FFF" stopOpacity="0"></stop>
              <stop offset="100%" stopColor="#FFF"></stop>
              <stop offset="100%" stopColor="#FFF" stopOpacity="0.968"></stop>
            </linearGradient>
            <linearGradient id="border-gradient" x1="0" x2="0" y1="0" y2="1">
              <stop offset="0%" stop-color="#FFF"></stop>
              <stop offset="100%" stop-color="#FFF" stop-opacity="0"></stop>
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(0 -680)">
              <g transform="translate(0 680)">
                <mask id="mask-2" fill="#fff">
                  <use xlinkHref="#path-1"></use>
                </mask>

                <g fillRule="nonzero" mask="url(#mask-2)">
                  <use
                    fill="#000"
                    filter="url(#filter-4)"
                    xlinkHref="#path-3"
                  ></use>
                  <use fill="#FFF" xlinkHref="#path-3" stroke="url(#border-gradient)"></use>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <Container
          fluid
          className={'position-relative diagonal-background-inner'}
        >
          <Row>
            <Col>
              <h2>{title}</h2>
            </Col>
          </Row>
          <Row>
            {columns?.map((column, index) => {
              return (
                <React.Fragment key={index}>
                  {index === 2 ? (
                    <Col
                      sm={4}
                      className={'d-none d-sm-flex d-md-flex d-lg-none'}
                    />
                  ) : (
                    ''
                  )}
                  {column.title && (
                    <Col
                      className={'content'}
                      xs={columnSizes[columns?.length]?.xs}
                      sm={columnSizes[columns?.length]?.sm}
                      md={columnSizes[columns?.length]?.md}
                      lg={columnSizes[columns?.length]?.lg}
                    >
                      <h3>{column.title}</h3>
                      <MarkDownComponent>{getNestedProp(column.content)}</MarkDownComponent>
                      {/* <Link to={column.link.slug}>{column.link.title}</Link> */}
                    </Col>
                  )}
                </React.Fragment>
              );
            })}
            {images && (
              <Col className={'content'} xs={12} lg={4}>
                <Row className={'mt-5'}>
                  {images.map((image, index) => (
                    <Col xs={6} className={'mb-4'} key={index}>
                      <GatsbyImage
                        alt={image.title}
                        title={image.title}
                        fixed={image.fixed}
                      ></GatsbyImage>
                    </Col>
                  ))}
                </Row>
              </Col>
            )}
          </Row>
          <Row className={'my-20'}>
            {links &&
              links.map((link, index) => (
                <Col
                  xs={columnSizes[columns?.length]?.xs}
                  sm={columnSizes[columns?.length]?.sm}
                  md={columnSizes[columns?.length]?.md}
                  lg={columns?.length === 1 ? 3 : columnSizes[columns?.length]?.lg}
                >
                  <Button
                    className={'w-100 d-block'}
                    key={index}
                    text={link.title}
                    type={link.type}
                    {...link}
                  />
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </ColorContainer>
  );
};

DiagonalBackgroundComponent.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.oneOfType([PropTypes.array]),
  content: PropTypes.array,
  links: PropTypes.array
};
DiagonalBackgroundComponent.defaultProps = {
  title: '',
  columns: [],
  content: [],
  links: []
};

export default DiagonalBackgroundComponent;
