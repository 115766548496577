import PropTypes from 'prop-types';
import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AccordionContainer from './accordionContainer';
import MarkDownComponent from './markDown';
import { getNestedProp } from '../helper/code';

const TabsContainer = ({titleTab1, textTab1, accordionsTab1, titleTab2, textTab2, accordionsTab2, titleTab3, textTab3, accordionsTab3}) => {
    return (
        <Tabs
        defaultActiveKey="one"
        className="container-tabs"
        >
            {titleTab1 && (
            <Tab eventKey="one" title={titleTab1}>
                <MarkDownComponent>{getNestedProp(textTab1)}</MarkDownComponent>            
                {accordionsTab1 && (
                    <AccordionContainer accordions={accordionsTab1} />
                )}
            </Tab>  
            )}
            {titleTab2 && (
            <Tab eventKey="two" title={titleTab2}>
                <MarkDownComponent>{getNestedProp(textTab2)}</MarkDownComponent>            
                {accordionsTab2 && (
                    <AccordionContainer accordions={accordionsTab2}/>
                )}
            </Tab>
            )}
            {titleTab3 && (
            <Tab eventKey="three" title={titleTab3}>
                <MarkDownComponent>{getNestedProp(textTab3)}</MarkDownComponent>            
                {accordionsTab3 && (
                    <AccordionContainer accordions={accordionsTab3}/>
                )}
            </Tab>
            )}
        </Tabs>      
    );
};

TabsContainer.propTypes = {
    titleTab1: PropTypes.string,
    textTab1: PropTypes.object,
    accordionsTab1: PropTypes.arrayOf(PropTypes.shape(AccordionContainer.propTypes)),
    titleTab2: PropTypes.string,
    textTab2: PropTypes.object,
    accordionsTab2: PropTypes.arrayOf(PropTypes.shape(AccordionContainer.propTypes)),
    titleTab3: PropTypes.string,
    textTab3: PropTypes.object,
    accordionsTab3: PropTypes.arrayOf(PropTypes.shape(AccordionContainer.propTypes)),
};

TabsContainer.defaultProps = {
    titleTab1: '',
    textTab1: '',
    titleTab2: '',
    textTab2: '',
    titleTab3: '',
    textTab3: '',
};

export default TabsContainer;
