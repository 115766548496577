import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import MarkDownComponent from './markDown';
import { getNestedProp } from '../helper/code';

const AccordionContent= ({titleAccordion, contentAccordion}) => {
    const [isActive, setIsActive] = useState(false);

    const handleOpen = () => {
        setIsActive(!isActive);
    };

    return (  
        <div className="accordion-item">
            <div className="header" onClick={handleOpen}>
                {titleAccordion} 
                <FontAwesomeIcon icon={isActive ? faChevronUp : faChevronDown} />
            </div>
            <div className={`content ${isActive ? 'active' : '' }`}>
                <MarkDownComponent>{getNestedProp(contentAccordion)}</MarkDownComponent> 
            </div>
        </div>
    );
};

AccordionContent.propTypes = {
    titleAccordion: PropTypes.string,
    contentAccordion: PropTypes.object,
};

AccordionContent.defaultProps = {
    titleAccordion: '',
    contentAccordion: '',
};

export default AccordionContent;
