import PropTypes from "prop-types";
import React, { createContext } from "react";
import CookieConsentBanner from "../modules/cookie";
import Footer from "./footer";
import Navigation from "./navigation/navigation";

const defaultContext = {
    node_locale: 'de',
    microcopy: []
};
export const SettingsContext = createContext(defaultContext);

const Layout = ({ settings, children }) => {

    return (
        <SettingsContext.Provider value={settings}>
            <CookieConsentBanner />
            <div className="wrapper">
                <Navigation />
                <div className="layout__content">
                    {children}
                </div>
            </div>
            <Footer />

        </SettingsContext.Provider>
    )
}

Layout.propTypes = {
    settings: PropTypes.shape({
        node_locale: PropTypes.string,
        microcopy: PropTypes.object
    }),
}

Layout.defaultProps = {
    settings: {
        node_locale: 'de',
        microcopy: []
    }
}

export default Layout