import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getTranslation } from '../helper/translation';
import { SettingsContext } from './layout';
import Link from './link';

const Footer = () => {
    const { microcopy} = useContext(SettingsContext);
    
    return ( 
        <footer>
            <Container
                fluid
            >
                <Row className={'d-flex align-items-center footer-content'}>
                    <Col
                        className={'col-auto text-left mt-0 mr-auto order-2 order-sm-1'}
                    >
                        <span
                            className={'copyright'}
                        >
                            {getTranslation('footer.copyright', microcopy)}
                        </span>
                    </Col>
                    <Col
                        className={'col-auto d-flex footer-sub-navigation align-content-between order-1 order-sm-2'}
                    >
                        <Link to={getTranslation('contact.link', microcopy)}>{getTranslation('footer.contact', microcopy)}</Link>
                        <Link to={getTranslation('footer.dataProtectionLink', microcopy)}>{getTranslation('footer.dataProtection', microcopy)}</Link>   
                        <Link to={getTranslation('footer.imprintLink', microcopy)}>{getTranslation('footer.imprint', microcopy)}</Link>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer