import React, { useContext} from 'react';
import { SettingsContext } from '../modules/layout';
import SideNavItem from './sidenavItem';

const SideNav = () => {
    const { megaMenu, node_locale } = useContext(SettingsContext);

    return (
    <div>
        <div className="sidenav container-accordion">
            {megaMenu?.map(({ name, items, page},index) => (
                <SideNavItem key={index} name={name} items={items} page={page}/>
            ))}
        </div> 
    </div>
  );
};

export default SideNav;
