import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import Button from '../../../components/button';
import { SettingsContext } from '../../layout';
import ItemLink from './itemLink';
import MenuLink from './menuLink';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { cookieExists } from "../../../helper/cookie";

const MenuElement = ({ item, column }) => {
  const settings = useContext(SettingsContext);
  const { node_locale, isSearchPage } = settings;
  const [ isCookieRegistered, setCookieRegistered ] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCookieRegistered(cookieExists('registered'));
    }
  }, []);

  return (
    <Col
      key={item.contentful_id}
      className={`nav-menu-content`}
    >
      <div className="nav-menu-content-inner-wrapper">
        {item?.page ? (
          <>
            {item?.page?.localizedPage ? (
              <MenuLink
                item={item}
                locale={node_locale}
              />
            ) : null}
          </>
        ) : (
          <p className="nav-menu-title">
            {item?.icon && (
              <img
                className="mr-2"
                src={item?.icon?.file?.url}
                alt={item?.icon?.description || item?.icon?.title}
              />
            )}
            {item?.name}
          </p>
        )}
        
        {(item?.links && (item?.links.some((link) => link.visible === 'Show always')|| (isCookieRegistered && item?.links.some((link) => link.visible === 'Show when registered cookie is set') || (!isCookieRegistered && item?.links.some((link) => link.visible === "Hide when registered cookie is set"))))) && (
        <>
        <FontAwesomeIcon icon={faChevronRight} />
        <div className={`nav-menu submenu ${item.doubleColumn ? 'nav-items-link-container' : ''}`}>
          {item?.links?.map((itemLink) => { 
            const { name, description, title, link, type, internal, page, visible, contentful_id } = itemLink;
            const target = isSearchPage ? (page?.fullSlug || page?.url || link.target?.fullSlug) : link?.target?.fullSlug
            return internal?.type === 'ContentfulMegaMenuLink' ? (
              (visible === "Show always" || (isCookieRegistered && visible === "Show when registered cookie is set") || (!isCookieRegistered && visible === "Hide when registered cookie is set")) && (
                <ItemLink
                key={contentful_id}
                name={name}
                page={page}
                description={description}
                item={item}
               />
              )
            ) : (
              <Button
                key={contentful_id}
                className="nav-menu-button"
                link={`/${target}`}  
                type={type}
                text={title}
              />
            );
          })}
        </div>
        </>
        )}
      </div>
    </Col>
  );
};

MenuElement.propTypes = {
  item: PropTypes.object,
  column: PropTypes.bool
};
MenuElement.defaultProps = {};

export default MenuElement;
