import React from "react";
import { Helmet } from "react-helmet";

const matomoTagManager = `var _mtm = window._mtm = window._mtm || [];
_mtm.push({
  'mtm.startTime': (new Date().getTime()),
  'event': 'mtm.Start'
});
var d = document,
  g = d.createElement('script'),
  s = d.getElementsByTagName('script')[0];
g.async = true;
g.defer = true;
g.src = 'https://webanalytics.4com.de/js/container_CWXG9HiK.js';
s.parentNode.insertBefore(g, s);
`;
const Tracking = () => {
    return (
        <Helmet>
            <script type="text/javascript" defer>
                {matomoTagManager}
            </script>
        </Helmet>
    )
}

export default Tracking