import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';
import Button from '../../../components/button';

const MenuPromotion = ({ item }) => {
  const target = item?.button?.link?.target?.fullSlug

  return item?.name ? (
    <Col
      xl={3}
      key={item.contentful_id}
      className={`nav-teaser-container  ${item.teaser && 'pl-0'}`}
    >
      <BackgroundImage
        alt={item?.teaser?.title}
        fixed={item?.teaser?.fixed}
        className="nav-teaser-img"
      >
        <div className="nav-teaser-content">
          <h4 className="nav-teaser-title">{item?.name}</h4>
          <p className="nav-teaser-text">{item?.description}</p>
          <Button
            className={'nav-teaser-button'}
            type={item?.button?.type}
            text={item?.button?.title}
            link={`/${target}`}
          />
        </div>
      </BackgroundImage>
    </Col>
  ) : null;
};

MenuPromotion.propTypes = {
  item: PropTypes.object,
};
MenuPromotion.defaultProps = {};

export default MenuPromotion;
