import PropTypes from 'prop-types';
import React from 'react';
import AccordionContent from './accordion';

const AccordionContainer= ({accordions}) => {
    return (  
        <div className="container-accordion">
            {accordions?.map((item,index) => 
                <AccordionContent key={index} titleAccordion={item.titleAccordion} contentAccordion={item.contentAccordion} />
            )}
        </div>   
    );
};

AccordionContainer.propTypes = {
    accordions: PropTypes.arrayOf(PropTypes.shape(AccordionContent.propTypes))
};

AccordionContainer.defaultProps = {
    accordions: {}
};

export default AccordionContainer;
