import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Link from '../modules/link';

const SideNavItem = ({ name, items, links, page}) => {
    const [isActive, setIsActive] = useState(false);

    const handleOpen = () => {
        setIsActive(!isActive);
    };

    return (
    <div className="accordion-item" >
       <div className="header" onClick={handleOpen}>
            {(items || links) ? (
                <>
                <p>{name}</p>
                <FontAwesomeIcon icon={isActive ? faChevronUp : faChevronDown} />
                </>
            ):(
                <Link
                to={
                name === 'home' ? '/' : `/${page?.fullSlug}`
                }
                >
                    {name}
                </Link>
            )}
        </div>
        <div className={`content ${isActive ? 'active' : '' }`}>
        {items ? (
            items.map((item,index) => (
            <SideNavItem key={index} name={item.name} items={item.items} links={item.links} page={item.page}/>
            ))
        ):(
            links?.map((link,index) => (
                <Link
                    key={index}
                    className={`${index == 0 ? 'active' : ''}`}
                    to={
                    name === 'home' ? '/' : `/${link.page?.fullSlug}`
                    }
                >
                    {link.name}
                </Link>
            ))
        )}
        </div>
    </div>
  );
};

SideNavItem.propTypes = {
    name: PropTypes.string,
    page: PropTypes.shape({
      slug: PropTypes.string,
      id: PropTypes.string
    }),
    links: PropTypes.array,
    items: PropTypes.array
};

SideNavItem.defaultProps = {
    name: '',
};

export default SideNavItem;
