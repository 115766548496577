import GatsbyImage from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import { getNestedProp } from "../helper/code";
import Link from "../modules/link";
import { imageDefaultProps, imagePropTypes } from "../propTypes/image";
import MarkDownComponent from './markDown'
import Button from './button';

const CarouselItem = ({ image, title, subtitle, caption, content, button, imageFullscreen, displayJustImage, display1Column }) => {
    return (
        <div className={"gallery-carousel-item carousel-type-" + (imageFullscreen ? (displayJustImage && display1Column) ? 'just-image' : 'image' : 'icon')}>
            <div className={`carousel-item-image ${!image ? 'no-image' : ''}`}>
                {image && (
                    <img src={image?.file?.url} alt={image?.description || image?.title}></img>
                )}
                {
                    (displayJustImage && caption) && (
                      <div className="gallery-image-caption">
                        <p>{caption}</p>
                      </div>
                    )
                }
            </div>
            {
                !displayJustImage && (
                <div className="gallery-carousel-item-text">
                    <div className="content-top">
                        <h5 className="mt-4 title">{title}</h5>
                        {subtitle && (
                            <h5 className="mb-3 subtitle">{subtitle}</h5>
                        )}
                    
                        <div className="mb-3">
                            <MarkDownComponent>{getNestedProp(content)}</MarkDownComponent>
                        </div>
                    </div>
                    {button && (
                        <div className="content-button">
                            <Button
                                text={button.title}
                                {...button}
                            />
                        </div>
                    )
                    }
                </div>
                )
            }
        </div>
    )
}

CarouselItem.propTypes = {
    image: PropTypes.shape(imagePropTypes),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    button: PropTypes.object,
    imageFullscreen: PropTypes.bool,
    displayJustImage: PropTypes.bool,
    display1Column: PropTypes.bool
}
CarouselItem.defaultProps = {
    title: "",
    content: {},
    imageFullscreen: true
}

export default CarouselItem
