import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import GalleryCarousel from './galleryCarousel';

const ContentModal = ({title, content, color, display1Column, display2Column, onClose}) => {
  return (
    <div className={`modal-container ${color}`}>
      <div className="container">
        <div className="close" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
        </div>

        <div className="body">
          <GalleryCarousel 
          title={title}
          showTitle={true}
          isCarousel={true}
          display1Column={display1Column}
          display2Column={display2Column}
          displayJustImage={false}
          infinite={true}
          items={content}/>
        </div>
      </div>
    </div>
  )
}

export default ContentModal;