import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import MenuElement from './menuElement';
import MenuPromotion from './menuPromotion';
import MenuWrapper from './menuWrapper';
import { cookieExists } from "../../../helper/cookie";

const COLUMN_TYPES = {
  ContentfulMegaMenuPromotion: MenuPromotion,
  ContentfulMegaMenuElement: MenuElement,
  ContentfulMegaMenuWrapper: MenuWrapper
}

const renderNavColumn = (props) => {
  const NavColumn = COLUMN_TYPES[props.item?.internal?.type]
  return NavColumn ? (
    <NavColumn {...props} />
  ) : null
}

export const NavMenu = ({ items }) => {
  const [ isCookieRegistered, setCookieRegistered ] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCookieRegistered(cookieExists('registered'));
    }
  }, []);

  return (
    <div className={`nav-menu`}>
      {
        items?.map(item => (item.visible === "Show always" || (isCookieRegistered && item?.visible === "Show when registered cookie is set") || (!isCookieRegistered && item?.visible === "Hide when registered cookie is set")) && renderNavColumn({item}))      }
    </div>
  );
};

NavMenu.propTypes = {
  firstLevelSlug: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.object,
      internal: PropTypes.shape({
        type: PropTypes.string
      }),
      page: PropTypes.shape({
        slug: PropTypes.string,
        id: PropTypes.string
      }),
      links: PropTypes.array
    })
  )
};

NavMenu.defaultProps = {
  firstLevelSlug: '',
  items: []
};