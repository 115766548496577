export const setCookie = cookie => {
    const cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);
    return document.cookie = `${cookie}=true; expires=${cookieDate.toUTCString()}; path=/`
}

export const cookieExists = cookie => {
    if (typeof window !== 'undefined') {
        return document.cookie.indexOf(cookie) > -1;
    }
   
}