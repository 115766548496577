import PropTypes from 'prop-types';
import React, { useState }  from "react";
import { Col, Container, Row } from 'react-bootstrap';
import TabsContainer from './tabs';
import CarouselItem from './galleryCarouselItem';
import SideNav from './sidenav';
import Link from '../modules/link';
import Button from './button';
import ContentModal from "./modal";

const DetailModuleAi = ({ title, subtitleModuleAi, areaCommercialColor, textScoreCard, buttonScoreCard, scoreCardTop1, scoreCardTop2, scoreCardTop3, scoreCardTop4, scoreCardBottom1, scoreCardBottom2, scoreCardBottom3, titleTab1, textTab1, accordionsTab1, titleTab2, textTab2, accordionsTab2, titleTab3, textTab3, accordionsTab3}) => {
  const [isModalTopOpen, setIsModalTopOpen] = useState(false);
  const [isModalBottomOpen, setIsModalBottomOpen] = useState(false);
  const [gallleryItems] = useState({
    itemsTop: [{ ...scoreCardTop1 },{ ...scoreCardTop2 },{ ...scoreCardTop3 },{ ...scoreCardTop4 }],
    itemsBottom: [{ ...scoreCardBottom1 },{ ...scoreCardBottom2 },{ ...scoreCardBottom3 }]
  });

  const toggleModalTopOpen = () => {
    setIsModalTopOpen((prev) => !prev);
  };

  const toggleModalBottomOpen = () => {
    setIsModalBottomOpen((prev) => !prev);
  };

  return (
    <>
    <Container fluid>
      <Row>
        <Col xl={10} className="container-module">
          <Row className={`row-content ${areaCommercialColor}`}>
            <Col md={6} className="content content-blue">
              <h2 className="title">{title}</h2>
              <h4 className="subtitle">{subtitleModuleAi}</h4>

              <TabsContainer titleTab1={titleTab1} textTab1={textTab1} accordionsTab1={accordionsTab1} titleTab2={titleTab2} textTab2={textTab2} accordionsTab2={accordionsTab2} titleTab3={titleTab3} textTab3={textTab3} accordionsTab3={accordionsTab3} />
            </Col>
            <Col md={6} className="content">
              <Row className="content-card-top">
                {scoreCardTop1 &&(
                <Col md={6} xl={3} className="pr-1 pl-1" onClick={toggleModalTopOpen}>
                  <CarouselItem 
                    title={scoreCardTop1.title}
                    subtitle={scoreCardTop1.subtitle}
                    image={scoreCardTop1.image}
                    content={scoreCardTop1.content}
                    button={scoreCardTop1.button}
                    key={scoreCardTop1.id}
                    displayJustImage={false}
                    display1Column={false}
                  />
                </Col>
                )}
                {scoreCardTop2 &&(
                <Col md={6} xl={3} className="pr-1 pl-1" onClick={toggleModalTopOpen}>
                  <CarouselItem 
                    title={scoreCardTop2.title}
                    subtitle={scoreCardTop2.subtitle}
                    image={scoreCardTop2.image}
                    content={scoreCardTop2.content}
                    button={scoreCardTop2.button}
                    key={scoreCardTop2.id}
                    displayJustImage={false}
                    display1Column={false}
                  />
                </Col>
                )}
                {scoreCardTop3 &&(
                <Col md={6} xl={3} className="pr-1 pl-1" onClick={toggleModalTopOpen}>
                  <CarouselItem 
                    title={scoreCardTop3.title}
                    subtitle={scoreCardTop3.subtitle}
                    image={scoreCardTop3.image}
                    content={scoreCardTop3.content}
                    button={scoreCardTop3.button}
                    key={scoreCardTop3.id}
                    displayJustImage={false}
                    display1Column={false}
                  />
                </Col>
                )}
                {scoreCardTop4 &&(
                <Col md={6} xl={3} className="pr-1 pl-1" onClick={toggleModalTopOpen}>
                  <CarouselItem 
                    title={scoreCardTop4.title}
                    subtitle={scoreCardTop4.subtitle}
                    image={scoreCardTop4.image}
                    content={scoreCardTop4.content}
                    button={scoreCardTop4.button}
                    key={scoreCardTop4.id}
                    displayJustImage={false}
                    display1Column={false}
                  />
                </Col>
                )}
                {isModalTopOpen && (
                  <ContentModal color={areaCommercialColor} title={title} content={gallleryItems.itemsTop} display2Column={true} onClose={toggleModalTopOpen} />
                )}
              </Row>
              <Row className="align-items-center">
                <Col md={9}>
                {textScoreCard &&(
                  <p className="text">{textScoreCard}</p> 
                )}
                </Col>
                <Col md={3}>
                {buttonScoreCard &&(
                  <Button
                    text={buttonScoreCard.title}
                    {...buttonScoreCard}
                    className={'w-100'}
                  />
                )}
                </Col>
              </Row>
              <Row className="mt-5 content-card-bottom">
               {scoreCardBottom1 &&(
                <Col md={4} onClick={toggleModalBottomOpen}>
                  <CarouselItem 
                    title={scoreCardBottom1.title}
                    subtitle={scoreCardBottom1.subtitle}
                    image={scoreCardBottom1.image}
                    content={scoreCardBottom1.content}
                    button={scoreCardBottom1.button}
                    key={scoreCardBottom1.id}
                    displayJustImage={false}
                    display1Column={false}
                  />
                </Col>
                )}
                {scoreCardBottom2 &&(
                <Col md={4} onClick={toggleModalBottomOpen}>
                  <CarouselItem 
                    title={scoreCardBottom2.title}
                    subtitle={scoreCardBottom2.subtitle}
                    image={scoreCardBottom2.image}
                    content={scoreCardBottom2.content}
                    button={scoreCardBottom2.button}
                    key={scoreCardBottom2.id}
                    displayJustImage={false}
                    display1Column={false}
                  />
                </Col>
                )}
                {scoreCardBottom3 &&(
                <Col md={4} onClick={toggleModalBottomOpen}>
                  <CarouselItem 
                    title={scoreCardBottom3.title}
                    subtitle={scoreCardBottom3.subtitle}
                    image={scoreCardBottom3.image}
                    content={scoreCardBottom3.content}
                    button={scoreCardBottom3.button}
                    key={scoreCardBottom3.id}
                    displayJustImage={false}
                    display1Column={false}
                  />
                </Col>
                )}
                {isModalBottomOpen && (
                  <ContentModal color={areaCommercialColor} content={gallleryItems.itemsBottom} display1Column={true} onClose={toggleModalBottomOpen} />
                )}
              </Row>  
            </Col>
          </Row>
        </Col>
        <Col xl={2}>
          <SideNav />
        </Col>  
      </Row>
    </Container>
    </>
  );
};

DetailModuleAi.propTypes = {
  title: PropTypes.string,
  subtitleModuleAi: PropTypes.string,
  areaCommercialColor: PropTypes.string,
  titleTab1: PropTypes.string,
  textTab1: PropTypes.object,
  accordionsTab1: PropTypes.arrayOf(PropTypes.shape({ 
    titleAccordion: PropTypes.string,
    contentAccordion: PropTypes.object,
  })),
  titleTab2: PropTypes.string,
  textTab2: PropTypes.object,
  accordionsTab2: PropTypes.arrayOf(PropTypes.shape({ 
    titleAccordion: PropTypes.string,
    contentAccordion: PropTypes.object,
  })),
  titleTab3: PropTypes.string,
  textTab3: PropTypes.object,
  accordionsTab3: PropTypes.arrayOf(PropTypes.shape({ 
    titleAccordion: PropTypes.string,
    contentAccordion: PropTypes.object,
  })),
  textScoreCard: PropTypes.string, 
  buttonScoreCard: PropTypes.shape(Button.propTypes),  
  scoreCardTop1: PropTypes.shape(CarouselItem.propTypes), 
  scoreCardTop2: PropTypes.shape(CarouselItem.propTypes), 
  scoreCardTop3: PropTypes.shape(CarouselItem.propTypes),  
  scoreCardTop4: PropTypes.shape(CarouselItem.propTypes),  
  scoreCardBottom1: PropTypes.shape(CarouselItem.propTypes),  
  scoreCardBottom2: PropTypes.shape(CarouselItem.propTypes),  
  scoreCardBottom3: PropTypes.shape(CarouselItem.propTypes),  
};

DetailModuleAi.defaultProps = {
  title: '',
  subtitleModuleAi: '',
  areaCommercialColor:'',
};

export default DetailModuleAi;
