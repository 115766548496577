import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { createMarkup, getNestedProp } from '../helper/code';

const CodeComponent = ({ code, codeJs }) => {
  const scriptRef = useRef();

  useEffect(() => {
    if(codeJs){
      const script = document.createElement('script');

      script.text = codeJs.codeJs;

      scriptRef.current.appendChild(script);

      return () => {
        scriptRef.current.removeChild(script);
      };
    }
  }, []);

  return (
    <Container fluid>
      <div
        ref={scriptRef}
        className="codeContainer"
        dangerouslySetInnerHTML={createMarkup(getNestedProp(code))}></div>
    </Container>
  )
};

CodeComponent.propTypes = {
  code: PropTypes.oneOfType([
    PropTypes.shape({
      code: PropTypes.string
    }),
    PropTypes.string
  ]),
  codeJs: PropTypes.oneOfType([
    PropTypes.shape({
      codJs: PropTypes.string
    }),
    PropTypes.string
  ])
};

CodeComponent.defaultProps = {
  code: {
    code: ''
  },
  codeJs: {
    codeJs: ''
  }
};

export default CodeComponent;