import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import Link from '../modules/link';

const CubeNavigation = ({ title, links }) => {
  const [sticky, setSticky] = useState(false);
  const [offset, setOffset] = useState(0);
  const [offsetDistance, setOffsetDistance] = useState(15);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [navigationIsSticky, setNavigationIsSticky] = useState(false);
  const cubeNavigationRef = useRef();
  const cubeRowRef = useRef();

  const [location, setLocation] = useState(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [activeLink, setActiveLink] = useState(-1);

  useEffect(() => {
    links
      .filter((link) => link?.target?.slug === 'home')
      .map((link) => {
        link.target.slug = ''
        link.target.fullSlug = ''
      });
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let { pathname } = window?.location;

      if (pathname.indexOf('/en/') > -1) {
        pathname = pathname.slice(4);
      }

      if (pathname[0] === '/') {
        return setLocation(pathname.substr(1));
      }

      return setLocation(pathname);
    }
  });

  useEffect(() => {
    if (cubeNavigationRef.current) {
      const { offsetTop, offsetHeight } = cubeNavigationRef.current;
      const navigationHeight = isDesktop ? (navigationIsSticky ? 90 : 139) : 66;
      setOffset(offsetTop - navigationHeight);
    }
  }, []);

  useEffect(() => {
    const onScroll = () => {
      setScrollTop(window.scrollY);
      setSticky(scrollTop > offset);
      setNavigationIsSticky(scrollTop > 70);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  const handleResize = () => {
    if (cubeRowRef.current && window) {
      const { offsetLeft } = cubeRowRef?.current;
      const { innerWidth } = window;
      const sizeInPercent = (offsetLeft * 100) / innerWidth + 3;
      setOffsetDistance(sizeInPercent);
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const active = links
    .findIndex(
      (link) => link?.target?.fullSlug === location?.substring(0, location.length - 1)
    );

  setActiveLink(active);
  })

  return (
    <>
    {links?.length > 0 && (
      <nav
        ref={cubeNavigationRef}
        className={`cube-navigation length-${links.length - 1}
        ${sticky ? 'sticky' : ''} active-${activeLink}`}
      >
          <div
            className="skewBackground d-none d-lg-block"
            style={{
              width: `${offsetDistance}%`
            }}
          />
        <Container fluid className="custom-cube-container">
          <Row ref={cubeRowRef} className="position-relative">
            <div
              className="skewBackground-mobile d-block d-lg-none"
            />

            {links.map((link, index) => {
              const active = index === activeLink;
              return (
                <Col
                  className={`cube-navigation-column text-center ${
                    active ? 'active' : ''
                  }`}
                  as={Link}
                  to={link}
                >
                  <span className={`cube-navigation-link`}>
                    {link.title}
                  </span>
                </Col>
              );
            })}
          </Row>
        </Container>
      </nav>
    )}
    {sticky && <span className={'sticky-placeholder'} />}
    </>
  );
};

CubeNavigation.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      target: PropTypes.shape({
        id: PropTypes.string
      })
    })
  )
};

CubeNavigation.defaultProps = {
  title: '',
  links: []
};

export default CubeNavigation;
