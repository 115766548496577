import parse from 'html-react-parser';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { format } from '../components';
import { getNestedProp } from '../helper/code';
import Thumbnail from '../images/header.png';
import { SettingsContext } from './layout';

const getHreflangLink = (locale, translatedPageSlug) => {
  if (!translatedPageSlug) {
    return
  }

  return `https://benchmark.vier.ai/${locale === 'de' ? 'en/' : ''}${
    translatedPageSlug.replace(/\/$/, "") + '/' || ''}`
}

const SEO = ({
  description,
  lang,
  structuredData,
  title,
  author,
  metaTags,
  ogImage,
  globalCss,
  slug
}) => {
  globalCss = getNestedProp(globalCss);
  structuredData = structuredData?.[0];

  const [isClient, setIsClient] = useState(false);
  const metaDescription = description;
  const tagsFromContentful =  Array.isArray(metaTags) ? metaTags : [metaTags || '']
  const { node_locale, id, translatedPageSlug } = useContext(SettingsContext);
  const [hrefLangLink] = useState(getHreflangLink(node_locale, translatedPageSlug));
  const locales = ['de', 'en'];

  useEffect(() => {
    if (typeof window !== "undefined") {
        setIsClient(true);
    }
  }, []);

  const parseStructuredData = (structuredData) => {
    if (structuredData?.logo?.file?.url) {
      structuredData.logo = structuredData.logo.file.url;
    }
    const prefix = 'ContentfulStructuredData';
    const type = structuredData.internal?.type.slice(prefix.length);

    structuredData['@context'] = 'https://schema.org';
    structuredData['@type'] = type;

    const tmp = omit(structuredData, ['internal']);

    return JSON.stringify(tmp);
  };

  const replaceTag = (element) => {
    if (!element) return;

    let { type } = element;

    if (typeof window !== 'undefined') {
      if (type === 'script' || type === 'style') {
        const oldElement = document.getElementById(`${type}-tag`);
        if (oldElement) {
          oldElement.remove();
        }

        const el = document.createElement(type);
        el.id = `${type}-tag`;

        if (el?.attribs?.src) {
          el.src = element?.attribs?.src;
        }

        el.append(element?.children?.map((item) => item.data));
        document?.head?.appendChild(el);
      }
    }
  };

  const renderHreflangLinks = () => {
    return locales.map((loc) => {
      if (loc === node_locale) {
        return (
          <link
            rel="alternate"
            href={`https://benchmark.vier.ai${slug?.replace(/\/$/, "")}/`}
            hrefLang={loc}
            key={loc}
          />
        );
      } else if(translatedPageSlug) {
        return (
          <link 
            rel="alternate" 
            href={hrefLangLink}
            hrefLang={loc} 
            key={loc} 
          />
        );
      }
    });
  };

  const renderHreflangLinksDef = () => {

    if (node_locale === 'en' || (node_locale === 'de' && !translatedPageSlug)) {
      return (
        <link
          rel="alternate"
          href={`https://benchmark.vier.ai${ slug === '/' ? '/en': slug?.replace(/\/$/, "")}/`}
          hrefLang="x-default"
        />
      );
    } else {
      return (
        <link 
          rel="alternate" 
          href={hrefLangLink}
          hrefLang="x-default"
        />
      );
    }
  };

  const renderMetaTags = () => {
    const meta=[
      {
        name: `description`,
        content: metaDescription
      },
      {
        property: `og:title`,
        content: title
      },
      {
        property: 'og:image',
        content: format(ogImage)?.file?.url || Thumbnail
      },
      {
        property: `og:description`,
        content: metaDescription
      },
      {
        property: `og:type`,
        content: `website`
      },
      {
        property: `og:url`,
        content: `https://benchmark.vier.ai${slug}`
      },
      {
        name: `twitter:card`,
        content: `summary`
      },
      {
        name: `twitter:creator`,
        content: author
      },
      {
        name: `twitter:title`,
        content: title
      },
      {
        name: `twitter:description`,
        content: metaDescription
      }
    ].map(tag =>{
      const attributes = Object.keys(tag)
      const values = Object.values(tag)
      return `<meta ${attributes[0]}='${values[0]}' ${attributes[1]}='${values[1]}'>`
    })

    return parse(meta.concat(tagsFromContentful).join(" "), {
        trim: true,
        replace: (domNode) => replaceTag(domNode)
      })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={title}

    >
      {renderHreflangLinks()}
      {renderHreflangLinksDef()}
      {renderMetaTags()}
    
      {globalCss &&
        parse(`<style>${globalCss}</style>`, {
          trim: true,
          replace: (domNode) => replaceTag(domNode)
        })}
      {structuredData && (
        <script type="application/ld+json">
          {parseStructuredData(structuredData)}
        </script>
      )}
      <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-version={isClient && window.location.hostname === "localhost" ? "preview": ""} data-settings-id="_mJsi2DA_lP_js" async></script>
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `de`,
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  metaTags: PropTypes.arrayOf(PropTypes.string),
  ogImage: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string
    })
  })
};

export default SEO;
